<script setup lang="ts">
import type { SearchAllItemType } from '@store'
import { ROUTES } from '@configs'
import type { TabData, TabDataMutable } from '@components/NovaTab/NovaTab.types'

type SearchType = TabDataMutable<TabData<SearchAllItemType>>

const route = useRoute()
const { t, locale, messages } = useI18n()
const searchStore = useSearchStore()

const ROUTES_LOOKUP: { [Key in SearchAllItemType]: string } = {
  post: ROUTES.SEARCH_DETAIL.path,
  comment: ROUTES.SEARCH_DETAIL_COMMENT.path,
  hash: ROUTES.SEARCH_DETAIL_HASHTAG.path,
  premium: ROUTES.SEARCH_DETAIL_NOVAPLUS.path,
  user: ROUTES.SEARCH_DETAIL_USER.path,
}
const tabId = computed<SearchAllItemType>(() => {
  const name = useGetRouteName()
  switch (name) {
    case ROUTES.SEARCH_DETAIL_COMMENT.name:
    case ROUTES.SEARCH_DETAIL_COMMENT_DETAIL.name:
      return 'comment'
    case ROUTES.SEARCH_DETAIL_HASHTAG.name:
      return 'hash'
    case ROUTES.SEARCH_DETAIL_NOVAPLUS.name:
    case ROUTES.SEARCH_DETAIL_NOVAPLUS_DETAIL.name:
      return 'premium'
    case ROUTES.SEARCH_DETAIL_USER.name:
      return 'user'
    case ROUTES.SEARCH_DETAIL.name:
    case ROUTES.SEARCH_DETAIL_POST.name:
    default:
      return 'post'
  }
})
const tabs = computed(
  () =>
    Object.keys(messages.value[locale.value].searchResultItemType).map(
      (key) => ({
        id: key,
        name: t(`searchResultItemType.${key}`),
      })
    ) as SearchType[]
)
const isSearchHome = computed(() =>
  route.matched.find(
    (item) => useGetRouteName(String(item.name)) === ROUTES.SEARCH.name
  )
)

// 최초 랜딩시 쿼리 스트링에 검색어가 있을경우 스토어 업데이트
// 최초 랜딩시 검색 타입(post, comment, hash)에 따라 탭 활성화
if (route.query.keyword) {
  searchStore.updateKeyword(route.query.keyword as string)
  // activeSearchType.value = route.query.searchType
  //   ? (route.query.searchType as SearchAllItemType)
  //   : 'post'
}

// 탭 변경시 콘텐츠 영역 변경
const handleOnChangeTab = (tab: SearchType) => {
  const url = ROUTES_LOOKUP[tab.id]
  useNavigations({
    url,
    query: route.query,
  })
}
</script>

<template>
  <NovaLayoutWrapper>
    <NovaHeader />

    <NovaLayoutMain :page-mode="true">
      <template #left>
        <NovaHeaderLeftSide :is-scroll="false">
          <NovaBoxRecentSearches />
        </NovaHeaderLeftSide>
      </template>

      <template #center>
        <div class="page-search">
          <div v-if="!isSearchHome" class="header">
            <h4
              v-dompurify-html="
                t('statusMessages.search.success', {
                  keyword: searchStore.keyword,
                })
              "
              class="search-keyword"
            />

            <NovaTab
              :init-tab="tabId"
              :tab-value="tabs"
              :size="62"
              :theme="'blue'"
              class="search-type-tabs"
              @on-change-tab="handleOnChangeTab"
            />
          </div>

          <div class="search-result-detail">
            <NuxtPage />
          </div>
        </div>
      </template>
    </NovaLayoutMain>
  </NovaLayoutWrapper>
</template>

<style lang="scss" scoped>
.aside-wrap {
  padding: 40px 0;
  width: 100%;
  height: 100%;
}

.page-search {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;

  > .header {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    padding: 40px 0 0;

    .search-keyword {
      flex-shrink: 0;
      @include text-style($text-heading2-medium);

      :deep(strong) {
        @include text-style($text-heading2-bold);
      }
    }

    .search-type-tabs {
      flex-shrink: 0;
      flex-grow: 0;

      :deep(.tabs) {
        height: 40px;
      }
    }
  }

  .search-result-detail {
    position: relative;
    flex-grow: 1;
    //height: 100%;

    .swiper {
      width: 100%;
    }

    .detail-item {
      //position: absolute;
      //top: 0;
      //left: 0;
      //width: 100%;
      //height: 100%;
      //opacity: 0;
      //pointer-events: none;
      //@include transition(opacity .2s ease-in-out);
      //display: none;

      &.on {
        opacity: 1;
        pointer-events: auto;
      }
    }
  }

  @include mobile {
    > .header {
      padding: 0 0;

      .search-keyword {
        display: none;
      }

      .search-type-tabs {
        background-color: $color-bg-3;

        :deep(.swiper-wrapper) {
          padding: 0 20px;
        }

        :deep(.tabs) {
          height: 55px;
        }
      }
    }
  }
}
</style>
