<script setup lang="ts">
import type {
  NovaBoxRecentSearchesItemEmits,
  NovaBoxRecentSearchesItemProps,
} from './NovaBoxRecentSearchesItem.types'

const { gtEvent } = useGoogleTag()
const emit = defineEmits<NovaBoxRecentSearchesItemEmits>()
const props = withDefaults(defineProps<NovaBoxRecentSearchesItemProps>(), {
  on: false,
})

const handleOnSearch = () => {
  gtEvent('clickEvent', {
    eventCategory: '클릭',
    eventAction: '검색 결과 > 최근 검색어',
    eventLabel: props.keyword,
    eventSlot: '',
    eventI18nAddr: props.keyword,
    eventComponent: 'Button',
  })
  emit('onSearch')
}

const handleOnRemove = () => {
  gtEvent('clickEvent', {
    eventCategory: '클릭',
    eventAction: '검색 결과 > 최근 검색어 삭제',
    eventLabel: 'close-compact',
    eventSlot: props.keyword,
    eventI18nAddr: 'close-compact',
    eventComponent: 'Button',
  })
  emit('onRemove')
}
</script>

<template>
  <div
    type="button"
    :class="['recent-search-item', { on }]"
    @click="handleOnSearch"
  >
    <span class="label">{{ keyword }}</span>
    <NovaButtonIcon
      :icon="{ type: 'outline', name: 'close-compact' }"
      :size="20"
      class="btn-remove"
      @click.stop="handleOnRemove"
    />
  </div>
</template>

<style lang="scss" scoped>
.recent-search-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  padding: 6px 7px;
  cursor: pointer;
  border-radius: 7px;
  user-select: none;

  &.on {
    background-color: $color-bg-1;
  }

  > .label {
    @include text-style($text-body-13-regular);
    @include ellipsis(1);
    color: $color-text-2;
  }

  > .btn-remove {
    color: $color-text-3;
  }
}
</style>
